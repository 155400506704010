import React, { Component } from "react";
import "../../index.less";
import { Col, Row } from "antd";
import Flatspage1 from "../../../../assert/Flatspage1.png";
import Flatspage2 from "../../../../assert/Flatspage2.png";
import Flatspage3 from "../../../../assert/Flatspage3.png";
import AMSright from "../../../../assert/AMSright.png";
import {page2CardMap,page2CardMap2, page2UlMap,page2Ul2Map} from './data'


export default class Flats extends Component {
  render() {
    return (
      <div>
        <div className="products_content">
          <div className="page">
            <span className="title">
              <h1>长租公寓解决方案</h1>
              <div>强大的IoT连接能力，引领租住新时代</div>
            </span>
            <div>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <div className="introduce right">
                    <h3>行业背景</h3>
                    <div className="line" />
                    <div className="introduce-details ">
                      <b>政策引领：</b>
                      城市化进程不断加快，为了满足多样化的住房需求，国家多次出台鼓励政策，促进租赁市场的发展。
                      <br />
                      <b>品牌竞争：</b>
                      行业整合速度加快，安全性不高、运营效率较低、服务质量低下的企业将被逐渐被淘汰。
                      <br />
                      <b>市场驱动：</b>
                      租赁群体年轻化，对居住需求已由价格敏感逐步转换为舒适敏感。
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <div className="introdece-img left">
                    <img src={Flatspage1} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="page odd">
            <span className="title-second">
              <h2>痛点分析</h2>
              <div className="line" />
            </span>
            <div className="row-width">
              <Row gutter={[32, 16]}>
                  {page2CardMap.map((item, key)=>{
                      return (
                        <Col xs={24} sm={12} md={12} lg={6} key={key}>
                        <div className="row-card">
                           <div className="card-img">
                               <img src={item.img} alt=''/>
                           </div>
                           <div className="card-content">
                                <div className="card-content-title">{item.title}</div>
                               <ul>
                                   {item.list.map((i,k)=>{
                                       return (
                                        <li key={i+k}><div className="list"/><div>{i}</div></li>
                                       )
                                   })}
                               </ul>
                           </div>
                        </div>
                    </Col>
                      )
                  })}
              </Row>
            </div>
          </div>
          <div className="page">
            <div>
              <div className="introduce">
                <h2>方案优势</h2>
                <div className="line" />
              </div>
              <div className="row-width">
              <Row gutter={[24, 16]}>
                  {page2CardMap2.map((item, key)=>{
                      return (
                        <Col xs={24} sm={12} md={12} lg={6} key={key}>
                        <div className="row-card2">
                           <div className="card-img">
                               <img src={item.img} alt=''/>
                           </div>
                           <div className="card-content">
                                <div className="card-content-title">{item.title}</div>
                               <ul>
                                   {item.list.map((i,k)=>{
                                       return (
                                        <li key={i+k}><div className="list"/><div>{i}</div></li>
                                       )
                                   })}
                               </ul>
                           </div>
                        </div>
                    </Col>
                      )
                  })}
              </Row>
            </div>
            </div>
          </div>
          <div className="page odd">
            <span className="title-second">
              <h2>场景方案 </h2>
              <div className="line" />
            </span>
            <div>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <div className="introdece-img right">
                    <img src={Flatspage2} alt="" />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <div className="introduce left">
                    <h3>有线场景</h3>
                    <div className="introduce-details other">
                    适用于新建项目，在弱电建设时预留有布线管道。M-Bus有线远传水表+RS485电表+水电集抄网关
                    </div>
                    <ul className="introduce-ul">
                      {page2UlMap.map((item, key) => {
                        return (
                          <li key={key}>
                            <img src={AMSright} alt="" />
                            {item.content}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
            
          </div>
          <div className="page odd">
            <div>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <div className="introduce right">
                    <h3>无线场景</h3>
                    <div className="introduce-details other">
                    适用于改造项目或户内安装等布线困难的场景。LoRa水表+LoRa电表+LoRa门锁+ LoRa小网关
                    </div>
                    <ul className="introduce-ul">
                      {page2Ul2Map.map((item, key) => {
                        return (
                          <li key={key}>
                            <img src={AMSright} alt="" />
                            {item.content}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <div className="introdece-img left">
                    <img src={Flatspage3} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}
