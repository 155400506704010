import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerLXSYDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import ImgPage from '../../ImgPage';
import DDSU4P1 from '../../../../../assert/DDSU4P1.png'
import DDSU4P2 from '../../../../../assert/DDSU4P2.png'
import DDSU4P3 from '../../../../../assert/DDSU4P3.png'
import DDSU4P4 from '../../../../../assert/DDSU4P4.png'
import DDSU4P5 from '../../../../../assert/DDSU4P5.png'
import DDSU4P6 from '../../../../../assert/DDSU4P6.png'
import DDSU4P7 from '../../../../../assert/DDSU4P7.png'
import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const DDSU4PimgMap =[
  DDSU4P1,DDSU4P2,DDSU4P3,DDSU4P4,DDSU4P5,DDSU4P6,DDSU4P7
]
export default class DDSU4P extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
      <ImgPage imgArr={DDSU4PimgMap} history={this.props.history}/>
    )
  }
}
