import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../Home/Banner2';
import {Helmet} from 'react-helmet';
import Pricing1 from '../../Home/Pricing1';
import Feature0 from '../../Home/Feature0';
import {Link, Route,  } from 'react-router-dom';
import { Tabs,Row, Col } from 'antd';
import {
    BannerHardwareDataSource,
    Feature00DataSource,
    Feature01DataSource,
    Feature02DataSource,
    Feature03DataSource
  } from '../../Home/data.source';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const { TabPane } = Tabs;

 class Hardware extends Component {
    constructor(props){
        super(props);
        this.state = {
            isMobile,
            activeKey:'waterMeter'
        }
    }
 callback = (key)=> {
    this.setState({activeKey:key})
  }
  componentDidMount(){
    document.documentElement.scrollTop = 0
  }
  render() {
    const {location,history,routes} = this.props
    const {activeKey} = this.state
    return (
      <>
      <Helmet>
      <title>源一硬件 | 智能水表电表热量表</title>
      <meta name="description" content="得益于物联网通信技术，智能化的水表电表热量表为租赁和能效两大场景提供数据基础" />
      <meta name="keywords" content="智能电表,智能水表,热量表,LoRa,NB-IoT" />
      </Helmet>
      <div> 
          <Banner2
      id="Banner2_0"
      key="Banner2_0"
      dataSource={BannerHardwareDataSource}
      isMobile={this.state.isMobile}
      location={location}
          />
        {location.pathname ==='/hardware' &&  <div>
          <Tabs activeKey={activeKey} onChange={this.callback} style={{maxWidth:'1200px',margin:'0 auto'}}>
    <TabPane tab="智能水表" key="waterMeter">
      <Feature0
        id="Feature0_0"
        key="waterMeter"
        dataSource={Feature00DataSource}
        isMobile={this.state.isMobile}
        history={history}
        tabKey="waterMeter"
      />
    </TabPane>
    <TabPane tab="智能电表" key="ElectricityMeter">
    <Feature0
        id="Feature0_0"
        key="ElectricityMeter"
        dataSource={Feature01DataSource}
        isMobile={this.state.isMobile}
        history={history}
        tabKey="ElectricityMeter"
      />
    </TabPane>
    <TabPane tab="智能热量表" key="HotMeter">
    <Feature0
        id="Feature0_0"
        key="HotMeter"
        dataSource={Feature02DataSource}
        isMobile={this.state.isMobile}
        history={history}
        tabKey="HotMeter"
      />
    </TabPane>
    <TabPane tab="智能网关" key="GatewayMeter">
    <Feature0
        id="Feature0_0"
        key="GatewayMeter"
        dataSource={Feature03DataSource}
        isMobile={this.state.isMobile}
        history={history}
        tabKey="GatewayMeter"
      />
    </TabPane>
         </Tabs>
          </div>}
          {
            routes.map((route,index)=>{
              if(route.exact){
                return  <Route key={index} path={route.path}  render={props => (
                  <route.component {...props} routes={route.routes} />
                )} exact/>
              }else {
                return  <Route key={index} path={route.path}  render={props => (
                  <route.component {...props} routes={route.routes} />
                )}/>
              }
            })
          }
    </div>
    </>
    )
  }
}
export default Hardware