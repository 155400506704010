import React, { Component } from "react";
import "../../index.less";
import { Col, Row } from "antd";
import Heyipage1 from "../../../../../assert/Heyipage1.png";
import Heyipage2 from "../../../../../assert/Heyipage2.png";
import Heyipage3 from "../../../../../assert/Heyipage3.png";
import Heyipage4 from "../../../../../assert/Heyipage4.png";
import AMSright from "../../../../../assert/AMSright.png";
import Footer from "../Footer";

const page2UlMap = [
  {
    content:
      "支持冷水表、热水表、电表、热表、多种表型，在计量精度、成本考虑、环境适应性为客户提供多样选择",
    details:
      "水表支持超声波、无磁、光电直读、单流表等多种计量方式，电表支持单相、三相、以及壁挂或者导轨安装方式",
  },
  {
    content: "支持多种通信方式、适应客户多种复杂的安装条件。",
    details:
      "M-Bus、RS485、LoRa、loRaWAN、NB-loT、GPRS、4G-LTE（Cat.1&Cat.4）、WiFi",
  },
  {
    content: "支持多种表计协议、为客户改造、集成已有表计提供快速接入能力。",
    details: "CJ/T 188、DL/T 645、ModBus、EN13757-4、EN1434",
  },
];

export default class Heyi extends Component {
  render() {
    return (
      <div>
        <div className="products_content">
          <div className="page">
            <span className="title">
              <h1>合一抄表智能管理系统</h1>
            </span>
            <div>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <div className="introduce right">
                    <h3>为客户提供专业的表计数据服务</h3>
                    <div className="line" />
                    <div className="introduce-details ">
                      合一抄表是源一物联专为公寓行业深度打造的表计管理平台，满足公寓场景客户对于表计应用的多种需求。
                      <br />
                      系统集成智能水、电、热能耗计量设备及多类型通信网关，提供安装配置、表计
                      集抄、远程通断、异常报警等管理功能，为客户精细化管理提供多维数据支撑。
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <div className="introdece-img left">
                    <img src={Heyipage1} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="page odd">
            <div>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <div className="introdece-img right">
                    <img src={Heyipage2} alt="" />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <div className="introduce left">
                    <h3>支持近百种物联网智能表计</h3>
                    <div className="line" />
                    <ul className="introduce-ul">
                      {page2UlMap.map((item, key) => {
                        return (
                          <li key={key} className="li-other">
                            <div>
                              <img src={AMSright} alt="" />
                            </div>
                            <div className="li-content">
                              {item.content}
                              <div>{item.details}</div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="page">
            <span className="title-second"></span>
            <div>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <div className="introduce right top">
                    <h3>为伙伴开放API对接服务</h3>
                    <div className="line" />
                    <div className="introduce-details ">
                      已与国内头部的公寓管理系统打通，为客户提供一站式服务。寓小二、蘑菇租房、房乾、全房通、巴乐兔、涂鸦、泊寓等
                      <br />
                      开放API对接服务，支持向第三方实时推送数据，愿和伙伴一起合作、相互成就。
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <div className="introdece-img left">
                    <img src={Heyipage3} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="page odd">
            <span className="title-second">
              <h2>由物联网平台保驾护航 </h2>
              <div className="line" />
              <div>
                物联网平台提供全面的设备管理、监控运维等功能，保障各平台的稳定运行。{" "}
              </div>
            </span>
            <div>
              <Row>
                <Col xs={24} sm={24} md={24}>
                  <div className="introdece-img">
                    <img src={Heyipage4} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Footer type='Heyi'/>
        </div>
      </div>
    );
  }
}
