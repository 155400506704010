import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerLXSYDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import CT2131 from '../../../../../assert/CT2131.png'
import CT2132 from '../../../../../assert/CT2132.png'
import CT2133 from '../../../../../assert/CT2133.png'
import CT2134 from '../../../../../assert/CT2134.png'
import CT2135 from '../../../../../assert/CT2135.png'
import CT2136 from '../../../../../assert/CT2136.png'
import CT2137 from '../../../../../assert/CT2137.png'
import CT2138 from '../../../../../assert/CT2138.png'
import ImgPage from '../../ImgPage';
import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const CT213imgMap =[
  CT2131,CT2132,CT2133,CT2134,CT2135,CT2136,CT2137,CT2138
]
export default class CT213 extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
      <ImgPage imgArr={CT213imgMap} history={this.props.history}/>
    )
  }
}
