import React, { Component } from "react";
import "../../index.less";
import { Col, Row } from "antd";
import AMSpage1 from "../../../../../assert/AMSpage1.png";
import AMSpage2 from "../../../../../assert/AMSpage2.png";
import AMSpage3 from "../../../../../assert/AMSpage3.png";
import AMSpage4 from "../../../../../assert/AMSpage4.png";
import AMSpage5 from "../../../../../assert/AMSpage5.png";
import AMSpage6 from "../../../../../assert/AMSpage6.png";
import AMSpage7 from "../../../../../assert/AMSpage7.png";
import AMSpage8 from "../../../../../assert/AMSpage8.png";
import AMSpage9 from "../../../../../assert/AMSpage9.png";
import AMSpage10 from "../../../../../assert/AMSpage10.png";
import AMSpage11 from "../../../../../assert/AMSpage11.png";
import AMSpage12 from "../../../../../assert/AMSpage12.png";
import AMSpage13 from "../../../../../assert/AMSpage13.png";
import AMSicon from "../../../../../assert/AMSicon.png";
import AMSright from "../../../../../assert/AMSright.png";
import Footer from "../Footer";

const page2UlMap = [
  {
    content: "两次催缴提前通知",
  },
  {
    content: "夜间不断电，具体断电时间可自定义",
  },
  {
    content: "公众号与短信两种方式",
  },
  {
    content: "水电费与租金皆可催缴",
  },
  {
    content: "断电与通电阈值自定义",
  },
  {
    content: "房间可单独保电设置",
  },
];

export default class AMS extends Component {
  render() {
    return (
      <div>
        <div className="products_content">
          <div className="page">
            <span className="title">
              <h1>AMS租赁管理系统</h1>
            </span>
            <div>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <div className="introduce right">
                    <h3>提供客户能源计量管理全链路解决方案</h3>
                    <div className="line" />
                    <div className="introduce-details ">
                      AMS租赁管理系统是源一物联专为公寓行业深度打造的能源计量与收费管理系统，可有效提升公寓运营的智能化水平。
                      <br />
                      系统以房源管理为核心，集成水、电、气、热能耗计量、智能门锁等设备管理功能，并提供多维数据分析报表。
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <div className="introdece-img left">
                    <img src={AMSpage1} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="page odd">
            <div>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <div className="introdece-img right other-img">
                    <img src={AMSpage2} alt="" />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <div className="introduce left">
                    <h3>多端协作助力高效运营和人性化催缴</h3>
                    <div className="line" />
                    <div className="introduce-details other">
                      全流程自动化智能处理：抄表结算、账单生成、账单推送、智能催缴、移动支付，B端客户省时省心省力。
                      <br />
                      支持对接客户公众号，支付直接对接客户，财务方面可高枕无忧。
                      <br />
                      账单推送一目了然，租户直接充值缴费，公开透明，有效减少沟通成本。
                    </div>
                    <ul className="introduce-ul">
                      {page2UlMap.map((item, key) => {
                        return (
                          <li key={key}>
                            <img src={AMSright} alt="" />
                            {item.content}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="page">
            <span className="title-second">
              <h2>公区分摊及设置 </h2>
              <div className="line" />
              <div>
                系统支持多种分摊方式：按面积/人数分摊、中央空调外机分摊，分摊表支持串并联等多种模式，每一笔分摊费用均可查。
              </div>
            </span>
            <div>
              <Row>
                <Col xs={24} sm={13} md={13}>
                  <div className="introdece-img right">
                    <img src={AMSpage3} alt="" />
                  </div>
                </Col>
                <Col xs={24} sm={11} md={11}>
                  <div className="introduce left">
                    <h3 className="noLine">场景一</h3>
                    <div className="introduce-details">
                      公区电表与房间电表并联，公区用量直接参与房间分摊。
                      <div style={{ width: "150px", margin: "16px  25% 12px" }}>
                        <img src={AMSpage4} alt="" width={"100%"} />
                      </div>
                      <br />
                      公区用量直接参与房间分摊，则在上图操作中公共区域与房间内新增表计时，无需勾选“总表”或“子表”。
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="page">
            <div>
              <Row>
                <Col xs={24} sm={11} md={11}>
                  <div className="introduce right">
                    <h3 className="noLine">场景二</h3>
                    <div className="introduce-details">
                     表计安装在公共区域的进线处，是一块总表。公区用量=公区总表-所有私区表计。

                      <div style={{ width: "150px", margin: "16px  25% 12px" }}>
                        <img src={AMSpage5} alt="" width={"100%"} />
                      </div>
                      <br />
                      公区电表是总表，则在上图操作中公共区域内新增表计时，需勾选“总表”，房间内新增表计时，需勾选“子表”。
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={13} md={13}>
                  <div className="introdece-img left">
                    <img src={AMSpage6} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="page">
            <div>
              <div className="introduce">
                <h3 className="noLine">分摊设置路径</h3>
                <div className="introduce-extra">【租赁管理】—— 房间详情页</div>
                <div className="introduce-details">
                  <Row gutter={[0, 8]}>
                    <Col xs={24} sm={8} md={8}>
                      <div className="introduce-content">
                        <div className="circle">1</div>{" "}
                        <div>
                          在公共区域找到“修改房间份额”按钮。
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={8} md={8}>
                      <div className="introduce-content">
                        <div className="circle">2</div>{" "}
                        <div >
                          修改份额前系统需要进行“水电费结算”。
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={8} md={8}>
                      <div className="introduce-content">
                        <div className="circle">3</div>{" "}
                        <div >
                          系统支持按比例进行分摊，例如输入各个房间面积，后台自动按面积比例进行分摊。{" "}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div>
                <Row>
                  <Col xs={24} sm={12} md={12}>
                    <div className="introdece-img right">
                      <div className="ams-img">
                        <img src={AMSpage7} alt="" />
                      </div>
                      <div className="ams-icon special">
                        <img src={AMSicon} alt="" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12}>
                    <div className="introdece-img left">
                      <div className="ams-other-img">
                        <img src={AMSpage8} alt="" />
                      </div>
                      <div className="ams-icon">
                        <img src={AMSicon} alt="" />
                      </div>
                      <div className="ams-other-img2">
                        <img src={AMSpage9} alt="" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="page">
            <div>
              <div className="introduce">
                <h3 className="noLine">空调分摊</h3>
              </div>
              <div>
                <Row>
                  <Col xs={24} sm={12} md={12}>
                    <div className="introdece-img right air">
                      <img src={AMSpage10} alt="" />
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12}>
                    <div className="introdece-img left air">
                      <img src={AMSpage11} alt="" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="page odd">
            <span className="title-second">
              <h2>账单详尽、财务管理更精确 </h2>
              <div className="line" />
            </span>
            <div>
              <Row>
                <Col xs={24} sm={24} md={24}>
                  <div className="introdece-img">
                    <img src={AMSpage12} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="introduce">
              <div className="introduce-extra">
                菜单路径：【数据中心】—【账单汇总】
              </div>
              <div className="introduce-details">
                <Row gutter={[96, 8]}>
                  <Col xs={24} sm={12} md={12}>
                    <div className="introduce-content">
                      <div className="circle">1</div>
                      <div >
                        按时间维度，统计最近一次入住期间，租客所产生的水电用量，及账单费用。
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12}>
                    <div className="introduce-content">
                      <div className="circle">2</div>
                      <div >
                        公共区域用量也可参与房间的分摊，系统支持按比例/面积/人数等多种分摊方式，同时也支持公区总表分摊，及空调外机电量的分摊。
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="page">
            <div>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <div className="introduce right phone">
                    <h3>手机账单</h3>
                    <div className="line" />
                    <div className="introduce-details">
                      <Row gutter={[8, 48]}>
                        <Col xs={24} sm={24} md={24}>
                          <div className="introduce-content">
                            <div className="circle">1</div>
                            <div >
                              按时间维度，统计最近一次入住期间，租客所产生的水电用量，及账单费用。
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <div className="introduce-content">
                            <div className="circle">2</div>
                            <div >
                              公共区域用量也可参与房间的分摊，系统支持按比例 / 面积/人数等多种分摊方式，同时也支持公区总表分摊，及空调外机电量的分摊。
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <div className="introdece-img left">
                    <img src={AMSpage13} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Footer type='AMS'/>
        </div>
      </div>
    );
  }
}
