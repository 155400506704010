import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerLXSYDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import ImgPage from '../../ImgPage';
import DTSD51681 from '../../../../../assert/DTSD51681.png'
import DTSD51682 from '../../../../../assert/DTSD51682.png'
import DTSD51683 from '../../../../../assert/DTSD51683.png'
import DTSD51684 from '../../../../../assert/DTSD51684.png'
import DTSD51685 from '../../../../../assert/DTSD51685.png'
import DTSD51686 from '../../../../../assert/DTSD51686.png'
import DTSD51687 from '../../../../../assert/DTSD51687.png'
import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const DTSD5168imgMap =[
  DTSD51681,DTSD51682,DTSD51683,DTSD51684,DTSD51685,DTSD51686,DTSD51687
]
export default class DTSD5168 extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
      <ImgPage imgArr={DTSD5168imgMap} history={this.props.history}/>
    )
  }
}
