import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerLXSYDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import ImgPage from '../../ImgPage';
import JoyS5001 from '../../../../../assert/JoyS5001.png'
import JoyS5002 from '../../../../../assert/JoyS5002.png'
import JoyS5003 from '../../../../../assert/JoyS5003.png'
import JoyS5004 from '../../../../../assert/JoyS5004.png'
import JoyS5005 from '../../../../../assert/JoyS5005.png'
import JoyS5006 from '../../../../../assert/JoyS5006.png'
import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const JoyS500imgMap =[
    JoyS5001,JoyS5002,JoyS5003,JoyS5004,JoyS5005,JoyS5006
]
export default class JOYS500 extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
      <ImgPage imgArr={JoyS500imgMap} history={this.props.history}/>
    )
  }
}
