import Software from "../components/Software"
import Hardware from "../components/Hardware"
import LXSYset from "../components/Hardware/components/WaterMeter/LXSYset"
import JOYS663 from "../components/Hardware/components/WaterMeter/JOYS663set"
import LXDXYset from "../components/Hardware/components/WaterMeter/LXDXYset"
import JOYS500 from "../components/Hardware/components/WaterMeter/JOYS500set"
import DDSU2P from "../components/Hardware/components/ElectricityMeter/DDSU2P"
import DDSU4P from "../components/Hardware/components/ElectricityMeter/DDSU4P"
import DDS5168B from "../components/Hardware/components/ElectricityMeter/DDS5168B"
import DTSD5168 from "../components/Hardware/components/ElectricityMeter/DTSD5168"
import JOYR200 from "../components/Hardware/components/HotMeter/JOYR200"
import JOYH100 from "../components/Hardware/components/HotMeter/JOYH100"
import CT213 from "../components/Hardware/components/GatewayMeter/CT213"
import CV151 from "../components/Hardware/components/GatewayMeter/CV151"
import GW220 from "../components/Hardware/components/GatewayMeter/GW220"
import Products from "../components/Software/Products"
import Solution from "../components/Solution"
import About from "../components/About"
import Tool from "../components/Tool"
//修改硬件产品等跳转路由地址，需要在DataSource中同步修改
export const routes = [
    {
        path:'/software',
        component:Software,
        routes:[
            {
                path:'/software/products',
                component:Products
            }
        ]
    },
    {
        path:'/hardware',
        component:Hardware,
        routes:[
            {
                path:'/hardware/mec',
                component:LXSYset,
            },
            {
                path:'/hardware/s663',
                component:JOYS663,
            },
            {
                path:'/hardware/voha',
                component:LXDXYset
            },
            {
                path:'/hardware/ddsu2p',
                component:DDSU2P
            },
            {
                path:'/hardware/ddsu4p',
                component:DDSU4P
            },
            {
                path:'/hardware/dds5168b',
                component:DDS5168B
            },
            {
                path:'/hardware/dtsd5168',
                component:DTSD5168
            },
            {
                path:'/hardware/r200',
                component:JOYR200
            },
            {
                path:'/hardware/h100',
                component:JOYH100
            },
            {
                path:'/hardware/ct213',
                component:CT213
            },
            {
                path:'/hardware/cv151',
                component:CV151
            },
            {
                path:'/hardware/gw200',
                component:GW220
            },
            {
                path:'/hardware/s500',
                component:JOYS500
            },

        ]
    },
    {
        path:'/solution',
        component:Solution,
        routes:[]
    },
    {
        path:'/about',
        component:About,
        routes:[]
    },
    {
        path:'/tool',
        component:Tool,
        routes:[]
    },
  
]
