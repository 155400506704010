import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import {Helmet} from 'react-helmet';
import Banner2 from '../../Home/Banner2';
import Content1 from '../../Home/Content1';
import Feature2 from '../../Home/Feature2';
import { Route,  } from 'react-router-dom';
import {
  BannerSoftwareDataSource,
  Contentsoft10DataSource,
  Contentsoft20DataSource,
  Contentsoft30DataSource
  } from '../../Home/data.source';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class Software extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
render() {
  const {location,routes,history} = this.props
  return (
    <>
      <Helmet>
      <title>源一软件 | 计量行业专家</title>
<meta name="description" content="源一物联以合一抄表平台为基础，打造让租住更美好的AMS管理系统和瞄准节能目标的源一能效平台。"/>
<meta name="keywords" content="智能抄表,抄表平台,公寓管理系统,租赁管理系统,能效平台"/>
      </Helmet>
    <div> 
        <Banner2
         id="Banner2_0"
         key="Banner2_0"
         dataSource={BannerSoftwareDataSource}
         isMobile={this.state.isMobile}
         location={location}
             />
         {location.pathname ==='/software' && 
         <>
             <Content1
             id="Content1_0"
             key="Content1_0"
             dataSource={Contentsoft10DataSource}
             isMobile={this.state.isMobile}
             location={location}
             history={history}
             type={'Heyi'}
           />
           <Feature2
             id="Feature2_0"
             key="Feature2_0"
             dataSource={Contentsoft20DataSource}
             isMobile={this.state.isMobile}
             location={location}
             history={history}
           />
             <Content1
             id="Feature1_0"
             key="Feature1_0"
             dataSource={Contentsoft30DataSource}
             isMobile={this.state.isMobile}
             location={location}
             history={history}
             type={'Eoi'}
           />
           </>
       }   
          {
            routes.map((route,index)=>{
              if(route.exact){
                return  <Route key={index} path={route.path}  render={props => (
                  <route.component {...props} routes={route.routes} />
                )} exact/>
              }else {
                return  <Route key={index} path={route.path}  render={props => (
                  <route.component {...props} routes={route.routes} />
                )}/>
              }
            })
          }
  </div>
  </>
  )
}
}
export default Software