import React, { Component } from "react";
import { enquireScreen } from "enquire-js";
import Pricing1 from "../../../Home/Pricing1";
import Feature0 from "../../../Home/Feature0";
import { Link, Route } from "react-router-dom";
import { Tabs, Row, Col } from "antd";
import AMS from "./components/AMS";
import Heyi from "./components/Heyi";
import Eoi from "./components/Eoi";

import {
  Feature00DataSource,
  Feature01DataSource,
  Feature02DataSource,
} from "../../../Home/data.source";
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const { TabPane } = Tabs;

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      activeKey: "Heyi",
    };
  }
  callback = (key) => {
    this.setState({ activeKey: key });
  };
  componentDidMount(){
    const {type} = this.props.location.state
    this.setState({ activeKey: type })
    document.documentElement.scrollTop = 0
  }

  render() {
    const { activeKey } = this.state;
    return (
      <div>
        <Tabs
          activeKey={activeKey}
          onChange={this.callback}
          style={{ maxWidth: "1200px", margin: "0 auto" }}
        >
          <TabPane
            tab="合一抄表平台"
            key="Heyi"
            style={{ backgroundColor: "#FAFBFD" }}
          >
            <Heyi />
          </TabPane>
          <TabPane
            tab="AMS管理平台"
            key="AMS"
            style={{ backgroundColor: "#FAFBFD" }}
          >
            <AMS />
          </TabPane>
          <TabPane
            tab="源一能耗平台"
            key="Eoi"
            style={{ backgroundColor: "#FAFBFD" }}
          >
            <Eoi />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
export default Products;
