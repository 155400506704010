import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';

class Content extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  productDetal = (item)=>{
    const {history,tabKey} = this.props
    history.push({pathname:`/hardware/${item.name}`,state:{tabKey}})
  }
  render() {
    const { dataSource, isMobile, ...other } = this.props;
    const {
      wrapper,
      titleWrapper,
      page,
      OverPack: overPackData,
      childWrapper,
    } = dataSource;
    return (
      <div {...other} {...wrapper}>
        <div {...page}>
          <div {...titleWrapper}>
            {titleWrapper.children.map(getChildrenToRender)}
          </div>
          
           
              {childWrapper.children.map((block, i) => {
                const { children: item, ...blockProps } = block;
                return (
                  <Col key={i.toString()} {...blockProps}>
                    <div {...item} onClick={()=>{this.productDetal(item)}}>
                      {item.children.map(getChildrenToRender)}
                    </div>
                  </Col>
                );
              })}
           
        
        </div>
      </div>
    );
  }
}

export default Content;
