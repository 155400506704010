import React from 'react';
import TweenOne from 'rc-tween-one';
import {Menu} from 'antd';
import { getChildrenToRender } from './utils';
import {Link,withRouter} from 'react-router-dom'
import down from '../assert/down.svg'
import PubSub from 'pubsub-js'

const { SubMenu,Item } = Menu;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
      selectedKeys:[],
      openKeys:[]
    };
  }
  //控制手机端导航栏展开项
  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };
  clearSelectKeys= (e)=>{
    this.setState({selectedKeys:[],phoneOpen:false})
    this.props.history.replace('/')
    document.documentElement.scrollTop=0
    
  }
  setOpenkey = (key)=>{
    this.setState({openKeys:key})
  }

  componentDidMount(){
    const { isMobile } = this.props;
    const doc = document.getElementsByClassName("header0")[0]
    doc.className = 'header0 home-page-wrapper nav-backgroud'
    const a= document.documentElement.scrollTop
    if(!isMobile){
      if(a !== 0){
        doc.style.background='rgba(0, 0, 0, 0.75)'
      }else {
        doc.style.background='transparent'
        
      }
    }
  
    window.onscroll = function(){
      const b= document.documentElement.scrollTop
      if(!isMobile){
        if(b !== 0){
          doc.style.background='rgba(0, 0, 0, 0.75)'
        }else {
          doc.style.background='transparent'
        }
      }
    }
    // const {state}  = this.props.location
    // if( state){
    //   this.setState({selectedKeys:state.type})
    // }
    // const select =  pathname.split('/')[1]
  }

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen,selectedKeys,openKeys } = this.state;
    const navData = dataSource.Menu.children;
    
    const navChildren = navData.map((item,index) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              
              <div
                {...a}
                className={`header0-item-block ${a.className}`.trim()}
                style={{letterSpacing:'2px',fontFamily:'PingFangSC-Regular'}}
              >
                {a.children.map(getChildrenToRender)}
               {
                 isMobile ?<></>  : <><img src={down} width={14} alt='' style={{marginLeft:'6px',verticalAlign:'middle',marginTop:'-3px'}}/></>
               } 

              </div>
             
            }
            popupClassName="header0-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.to ? (
               <Link to={{...childItem.to}}>
                <div {...childItem}> 
                  {childItem.children.map(getChildrenToRender)}
                </div>
                </Link>
               
              ) : (
                <div {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                  
                </div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                  
                </Item>
              );
            })}
            
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <div  {...a} className={`header0-item-block ${a.className}`.trim()}>
          {a.children.map(getChildrenToRender)}
          </div>
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
            onClick={this.clearSelectKeys}
          >
         
            <img  src={dataSource.logo.children} alt="img" style={{cursor:'pointer'}}/>
           
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                    height: 0,
                    duration: 300,
                    onComplete: (e) => {
                      if (this.state.phoneOpen) {
                        e.target.style.height = 'auto';
                      }
                    },
                    ease: 'easeInOutQuad',
                  }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu
              mode={isMobile ? 'inline' : 'horizontal'}
              theme="dark"
              onSelect={({ item, key, keyPath, selectedKeys, domEvent })=>{
                PubSub.publish('msg',selectedKeys)
               this.setState({selectedKeys:[...selectedKeys]})
              }}
              selectedKeys={selectedKeys}
              onClick={()=>{
                this.phoneClick()
                this.setState({openKeys:[]})
              }}
              openKeys={openKeys}
              onOpenChange={(openKeys)=>{this.setOpenkey(openKeys)}}
            >
              {navChildren}
            </Menu>
          </TweenOne>
        </div>
      </TweenOne>
    );
  }
}



export default withRouter(Header);
