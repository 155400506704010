import React, { Component } from 'react'
import './index.less'
class Rolltop extends Component {
  constructor(props){
    super(props);
    this.state={}
  }
  goTop = ()=>{
    let time ;
   if(document.documentElement.scrollTop >=3000){
     time =setInterval(()=>{
       if(document.documentElement.scrollTop >0){
         document.documentElement.scrollTop = document.documentElement.scrollTop-300
       }else {
         clearInterval(time)
       }
     },5)
   }else {
    time =setInterval(()=>{
      if(document.documentElement.scrollTop >0){
        document.documentElement.scrollTop = document.documentElement.scrollTop-150
      }else {
        clearInterval(time)
      }
    },5)
   }
  }

  render() {
    return (
      <div className='scrollTop' onClick={this.goTop}>返回顶部</div>
    )
  }
}
export default  Rolltop