import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import '../../index.less'
import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
export default class ImgPage extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location,imgArr} = this.props
    return (
      <div>
          <div style={{backgroundColor:'#f7f7f7'}}>
            
            <div className='lxsy'>
              {
                imgArr.map((item)=>{
                  return <div key={item}>
                  <img src={item} alt={item} width={'100%'}/>
                </div>
                })
              }
              {!isMobile && <div className='back'>
              <Button onClick={()=>{this.props.history.go(-1)}}>返回</Button>
              </div>}
            </div>
          </div>
      </div>
    )
  }
}
