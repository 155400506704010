import React, { Component } from "react";
import "../../index.less";
import { Col, Row } from "antd";
import PublicHousing1 from "../../../../assert/PublicHousing1.png";
import {page2CardMap,page2CardMap2} from './data'


export default class PublicHousing extends Component {
  render() {
    return (
      <div>
        <div className="products_content">
          <div className="page">
            <span className="title">
              <h1>公租房解决方案</h1>
              <div>强大的IoT连接能力，引领租住新时代</div>
            </span>
            <div>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <div className="introduce right top">
                    <h3>行业背景</h3>
                    <div className="line" />
                    <div className="introduce-details ">
                    近年来，各地公祖房建设持续推进，解决了大部分人口的居住问题，但由于缺乏一套成熟完善的运营管理机制，公租房租赁还存在诸多“痛点”、“难点”问题。
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <div className="introdece-img left">
                    <img src={PublicHousing1} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="page odd">
            <span className="title-second">
              <h2>痛点分析</h2>
              <div className="line" />
            </span>
            <div className="row-width">
              <Row gutter={[32, 16]}>
                  {page2CardMap.map((item, key)=>{
                      return (
                        <Col xs={24} sm={12} md={12} lg={6} key={key}>
                        <div className="row-card">
                           <div className="card-img">
                               <img src={item.img} alt=''/>
                           </div>
                           <div className="card-content">
                                <div className="card-content-title">{item.title}</div>
                               <ul>
                                   {item.list.map((i,k)=>{
                                       return (
                                        <li key={i+k}><div className="list"/><div>{i}</div></li>
                                       )
                                   })}
                               </ul>
                           </div>
                        </div>
                    </Col>
                      )
                  })}
              </Row>
            </div>
          </div>
          <div className="page">
            <div>
              <div className="introduce">
                <h2>方案优势</h2>
                <div className="line" />
              </div>
              <div className="row-width">
              <Row gutter={[24, 16]}>
                  {page2CardMap2.map((item, key)=>{
                      return (
                        <Col xs={24} sm={12} md={12} lg={6} key={key}>
                        <div className="row-card2">
                           <div className="card-img">
                               <img src={item.img} alt=''/>
                           </div>
                           <div className="card-content">
                                <div className="card-content-title">{item.title}</div>
                               <ul>
                                   {item.list.map((i,k)=>{
                                       return (
                                        <li key={i+k}><div className="list"/><div>{i}</div></li>
                                       )
                                   })}
                               </ul>
                           </div>
                        </div>
                    </Col>
                      )
                  })}
              </Row>
            </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}
