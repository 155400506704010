import Parkcard1 from "../../../../assert/Parkcard1.png";
import Parkcard2 from "../../../../assert/Parkcard2.png";
import Marketcard1 from "../../../../assert/Marketcard1.png";
import Flatscard4 from "../../../../assert/Flatscard4.png";
import Parkcard3 from "../../../../assert/Parkcard3.png";
import Parkcard4 from "../../../../assert/Parkcard4.png";
import Parkcard5 from "../../../../assert/Parkcard5.png";
import Parkcard6 from "../../../../assert/Parkcard6.png";

export const page2CardMap = [
    {
      img: Parkcard1,
      title:'电费',
      list:'占据物业管理成本的20%- 30%账单每月一出，电费消耗细节无法实时掌握，优化用电结构，降低电费成本难度较大'
    },
    {
      img: Marketcard1,
      title:'租赁',
      list:'租赁业务繁杂，办理入住，水电结算，账单核对等都需要人工支出，耗时耗力'
    },
    {
      img: Flatscard4,
      title:'安全',
      list:'设备异常无预警，事后抢修效率低。日常巡检靠自觉登记，缺少有效监管手段'
    },
    {
        img: Parkcard2,
        title:'节能',
        list:'缺少数字化的模型，配电支路不清晰，用电属性配比不合理，节能主体不明确，开展节能工作无从下手'
      },
  ];
export  const page2CardMap2 =[
      {
        img: Parkcard3,
        title:'园区能耗的数字化账单',
        list:'在线实时账单，电网、光伏、储能等能源消耗信息一目了然'
      },
      {
        img: Parkcard4,
        title:'租赁业务的数字化运营',
        list:'入住、退房、催缴、充值等全流程业务涵盖，管理方省时省力'
      },
      {
        img: Parkcard5,
        title:'关键设备的数字化运维',
        list:'设备在线监管，多样化预警设置，异常自动报警，保障园区安全'
      },
      {
        img: Parkcard6,
        title:'节能减排的数字化模型',
        list:'可视化图表，实时能源流向图，为管理者提供精准直观的数据支持'
      },
    ];
