import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerLXSYDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import JOYH1001 from '../../../../../assert/JOYH1001.png'
import JOYH1002 from '../../../../../assert/JOYH1002.png'
import JOYH1003 from '../../../../../assert/JOYH1003.png'
import JOYH1004 from '../../../../../assert/JOYH1004.png'
import JOYH1005 from '../../../../../assert/JOYH1005.png'
import JOYH1006 from '../../../../../assert/JOYH1006.png'
import JOYH1007 from '../../../../../assert/JOYH1007.png'
import JOYH1008 from '../../../../../assert/JOYH1008.png'
import ImgPage from '../../ImgPage';
import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const JOYH100imgMap =[
  JOYH1001,JOYH1002,JOYH1003,JOYH1004,JOYH1005,JOYH1006,JOYH1007,JOYH1008
]
export default class JOYH100 extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
      <ImgPage imgArr={JOYH100imgMap} history={this.props.history}/>
    )
  }
}
