import React, { Component, Fragment } from "react";
import "../../index.less";
import { Col, Row } from "antd";
import { pageMap } from "./data";
import Eoipage1 from "../../../../../assert/Eoipage1.png";
import Eoipage2 from "../../../../../assert/Eoipage2.png";
import Eoipage9 from "../../../../../assert/Eoipage9.png";
import Footer from "../Footer";


export default class Eoi extends Component {
  pageNode = (data, key) => {
    return (
      <div className={data.pageClassName} key={key}>
        {data.title && (
          <span className={data.className}>
            <h2>{data.title}</h2>
            {data.line && <div className="line" />}
          </span>
        )}
        <div>
          <Row>
            {data.leftContent.hasOwnProperty("img") ? (
              <Col xs={24} sm={12} md={12}>
                <div className={data.leftContent.img.className}>
                  <img src={data.leftContent.img.src} alt="" />
                </div>
              </Col>
            ) : (
              <Col xs={24} sm={12} md={12}>
                <div className={data.leftContent.introduce.className}>
                  <h3 className={data.leftContent.introduce.title.className}>
                    {data.leftContent.introduce.title.content}
                  </h3>
                  <div className={data.leftContent.introduce.details.className}>
                    {data.leftContent.introduce.details.content.map((item,key)=>{
                        return (
                            <Fragment key={key}>{item}<br/></Fragment>
                            ) 
                    })}
                  </div>
                </div>
              </Col>
            )}
            {data.rightContent.hasOwnProperty("img") ? (
              <Col xs={24} sm={12} md={12}>
                <div className={data.rightContent.img.className}>
                  <img src={data.rightContent.img.src} alt="" />
                </div>
              </Col>
            ) : (
              <Col xs={24} sm={12} md={12}>
                <div className={data.rightContent.introduce.className}>
                  <h3 className={data.rightContent.introduce.title.className}>
                    {data.rightContent.introduce.title.content}
                  </h3>
                  <div className={data.rightContent.introduce.details.className}>
                    {data.rightContent.introduce.details.content}
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <div className="products_content">
          <div className="page">
            <span className="title">
              <h1>源一物联能效管理平台</h1>
            </span>
            <div>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <div className="introdece-img right">
                    <img src={Eoipage1} alt="" />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <div className="introduce left">
                    <h3>专注于企业用电供电配电的信息管理</h3>
                    <div className="line line-other" />
                    <div className="introduce-details ">
                      源一能耗平台致力于提升能源管理的数字化水平，与现有办公数字化工具相集成（短信/企业微信/钉钉等），提供园区内的异常报警，保证园区安全运行，用物联网技术为企业插上了数字化“翅膀”。
                    </div>
                    <div className="introduce-img">
                      <img src={Eoipage2} alt="" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {pageMap.map((item, key) => {
            return this.pageNode(item, key);
          })}

          <div className="page">
            <span className="title-second">
              <h2>空调节能控制</h2>
              <div className="line" />
              <div className="line-text">
              空调在社会能耗里面比重达到13.5%，在建筑整体能耗支出占比60%-70%，是节能减排工作的重点目标。
对空调进行实时能耗/能效比监测，并加装物联网通信模块对空调进行远程参数配置，再辅以系统的自动化运维场景设计，三管齐下，为空调节能打造一套自循环的数字化模型。
              </div>
            </span>
            <div>
              <Row>
                <Col xs={24} sm={24} md={24}>
                  <div className="introdece-img">
                    <img src={Eoipage9} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
         
          </div>
          <Footer type='Eoi'/>
        </div>
      </div>
    );
  }
}
