import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerLXSYDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import GW2201 from '../../../../../assert/GW2201.png'
import GW2202 from '../../../../../assert/GW2202.png'
import GW2203 from '../../../../../assert/GW2203.png'
import GW2204 from '../../../../../assert/GW2204.png'
import GW2205 from '../../../../../assert/GW2205.png'
import GW2206 from '../../../../../assert/GW2206.png'
import GW2207 from '../../../../../assert/GW2207.png'
import GW2208 from '../../../../../assert/GW2208.png'
import ImgPage from '../../ImgPage';
import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const GW220imgMap =[
  GW2201,GW2202,GW2203,GW2204,GW2205,GW2206,GW2207,GW2208
]
export default class GW220 extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
      <ImgPage imgArr={GW220imgMap} history={this.props.history}/>
    )
  }
}
