import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../Home/Banner2';
import { Route,  } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import { Tabs, Row, Col } from "antd";
import Flats from './components/Flats';
import PublicHousing from './components/PublicHousing';
import Market from './components/Market';
import OfficeBuilding from './components/OfficeBuilding';
import Park from './components/Park';

import {
  BannerSoluntionDataSource,
  } from '../../Home/data.source';
import PubSub from 'pubsub-js'


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const { TabPane } = Tabs;
class Solution extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
        activeKey: "flats",
        flag:true
    }
}
callback = (key) => {
    this.setState({activeKey: key });
  };
componentDidMount(){
    const {state} = this.props.location
    if(state){
      this.setState({ activeKey: state.type })
    }
    PubSub.subscribe('msg',(msg,data)=>{
      this.setState({activeKey:data[0]})
    })
      document.documentElement.scrollTop = 0
    
  }
render() {
  const { location,routes,history } = this.props
  const { activeKey } = this.state;
  return (
    <>
      <Helmet>
      <title>源一方案 | 场景解决方案服务商</title>
<meta name="description" content="源一物联为公寓/公租房/工业园区/商业综合体/办公写字楼等业务场景提供一站式能源数字化解决方案。"/>
<meta name="keywords" content="长租公寓,智慧园区,公租房,智慧商场,智慧写字楼"/>
      </Helmet>
    <div> 
        <Banner2
         id="Banner2_0"
         key="Banner2_0"
         dataSource={BannerSoluntionDataSource}
         isMobile={this.state.isMobile}
         location={location}
        /> 
         <div>
        <Tabs
          activeKey={activeKey}
          onChange={this.callback}
          style={{ maxWidth: "1200px", margin: "0 auto" }}
        >
          <TabPane
            tab="长租公寓解决方案"
            key="flats"
            style={{ backgroundColor: "#FAFBFD" }}
          >
            <Flats />
          </TabPane>
          <TabPane
            tab="公租房解决方案"
            key="publicHousing"
            style={{ backgroundColor: "#FAFBFD" }}
          >
            <PublicHousing />
          </TabPane>
          <TabPane
            tab="智慧商场解决方案"
            key="market"
            style={{ backgroundColor: "#FAFBFD" }}
          >
            <Market />
          </TabPane>
          <TabPane
            tab="写字楼解决方案"
            key="officeBuilding"
            style={{ backgroundColor: "#FAFBFD" }}
          >
            <OfficeBuilding />
          </TabPane>
          <TabPane
            tab="园区能耗解决方案"
            key="park"
            style={{ backgroundColor: "#FAFBFD" }}
          >
            <Park />
          </TabPane>
        </Tabs>
      </div>
  </div>
  </>
  )
}
}
export default Solution