import OfficeBuildingcard1 from "../../../../assert/OfficeBuildingcard1.png";
import OfficeBuildingcard2 from "../../../../assert/OfficeBuildingcard2.png";
import OfficeBuildingcard3 from "../../../../assert/OfficeBuildingcard3.png";
import OfficeBuildingcard4 from "../../../../assert/OfficeBuildingcard4.png";
import OfficeBuildingcard5 from "../../../../assert/OfficeBuildingcard5.png";
import OfficeBuildingcard6 from "../../../../assert/OfficeBuildingcard6.png";
import OfficeBuildingcard7 from "../../../../assert/OfficeBuildingcard7.png";
import OfficeBuildingcard8 from "../../../../assert/OfficeBuildingcard8.png";

export const page2CardMap = [
    {
      img: OfficeBuildingcard1,
      title:'公共能源支出',
      list:'占据物业管理成本的20 %-30%，甚至更高；公区能耗如何分摊成为物业管理难题'
    },
    {
      img: OfficeBuildingcard2,
      title:'外机电费分摊',
      list:'在办公场景的电力消耗中，中央空调外机能耗是大头，分摊不均容易遭到业主质疑'
    },
    {
      img: OfficeBuildingcard3,
      title:'硬件管理方面',
      list:'硬件种类复杂，硬件提供商各自为阵，硬件管理分散在各个系统，无法统一'
    },
    {
        img: OfficeBuildingcard4,
        title:'财务收支管理',
        list:'每月租金水电结算，数量庞大，工作繁杂，人力管理成本难以释放'
      },
  ];
export  const page2CardMap2 =[
      {
        img: OfficeBuildingcard5,
        title:'场景化分摊设置',
        list:'支持多种公区分摊方式，按比例、面积、人数等分摊，同时支持公区总表分摊，每一笔分摊费用可查询'
      },
      {
        img: OfficeBuildingcard6,
        title:'空调外机电费分摊',
        list:'系统继等比例分摊发展到按面积分摊，后又实现按内机耗电量分摊外机电费的方案，各种场景轻松应对'
      },
      {
        img: OfficeBuildingcard7,
        title:'强大的硬件接入能力',
        list:'支持其他厂家同类型产品的接入，统一平台管理，打破数据孤岛'
      },
      {
        img: OfficeBuildingcard8,
        title:'多维度数据报表',
        list:'能耗明细、财务报表让运营管理一张图，详尽的抄表数据为避免用户用水用电纠纷，提供数据支撑'
      },
    ];
