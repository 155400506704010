import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerLXSYDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import CV1511 from '../../../../../assert/CV1511.png'
import CV1512 from '../../../../../assert/CV1512.png'
import CV1513 from '../../../../../assert/CV1513.png'
import CV1514 from '../../../../../assert/CV1514.png'
import CV1515 from '../../../../../assert/CV1515.png'
import CV1516 from '../../../../../assert/CV1516.png'
import CV1517 from '../../../../../assert/CV1517.png'
import ImgPage from '../../ImgPage';
import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const CV151imgMap =[
  CV1511,CV1512,CV1513,CV1514,CV1515,CV1516,CV1517
]
export default class CV151 extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
      <ImgPage imgArr={CV151imgMap} history={this.props.history}/>
    )
  }
}
