import React, { Component } from 'react'
import { Button } from "antd";

export default class Footer extends Component {

  jumpON=()=>{
    const { type } = this.props
    if(type === 'AMS'){
      window.open('http://ams.joymeter.com')
    }else if (type === 'Eoi'){
      window.open('http://amr.joymeter.com/mt')
    }else if (type === 'Heyi'){
      window.open('https://amr.metx.com.cn')
    }
  }
  render() {
   
    return (
        <div className="page footer">
        <span className="title-second">
          <h2>拥抱数字浪潮，共创美好未来</h2>
        </span>
          <div className="footer-buttom">
            <Button type="primary" shape="round" size="large"
             style={{color:'white',backgroundColor:'#3563B3'}}
             onClick={this.jumpON}
            >
              立即试用
            </Button>
          </div>
      </div>
    )
  }
}
