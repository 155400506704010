import Marketcard1 from "../../../../assert/Marketcard1.png";
import Flatscard2 from "../../../../assert/Flatscard2.png";
import Marketcard2 from "../../../../assert/Marketcard2.png";
import Flatscard5 from "../../../../assert/Flatscard5.png";
import Flatscard6 from "../../../../assert/Flatscard6.png";
import Flatscard7 from "../../../../assert/Flatscard7.png";

export const page2CardMap = [
    {
      img: Marketcard1,
      title:'租赁管理压力大',
      list:'从租赁合同到财务入账、租金支付、租金分析（多付少付/提前延后）的闭环管理每一步都需要人为跟踪'
    },
    {
      img: Flatscard2,
      title:'事物繁琐效率低',
      list:'能耗支出占据商业物业管理成本较大比重，异常现象难以掌握，大量人工支出导致商场运营现金流紧张'
    },
    {
      img: Marketcard2,
      title:'预付费模式困扰',
      list:'部分商户内有冰箱、冷柜等电器，欠费停电容易产生纠纷'
    },
  ];
export  const page2CardMap2 =[
      {
        img: Flatscard5,
        title:'全流程业务模式',
        list:'支持合同上传，租金录入，租金账单推送，在线租金缴纳，租金催缴，全业务流程服务'
      },
      {
        img: Flatscard6,
        title:'在线化管理机制',
        list:'商铺能耗，公区能耗精准分析 ，及时发现能源消耗异常情况 ，便于开展节能计划；每日结算，每日扣费，便于商场运营方快速回笼资金'
      },
      {
        img: Flatscard7,
        title:'人性化催缴设置',
        list:'对于部分特殊商户，系统支持保电设置；断电时间可以自定义，避免夜间断电'
      },
    ];
