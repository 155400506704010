import Eoipage3 from "../../../../../assert/Eoipage3.png";
import Eoipage4 from "../../../../../assert/Eoipage4.png";
import Eoipage5 from "../../../../../assert/Eoipage5.png";
import Eoipage6 from "../../../../../assert/Eoipage6.png";
import Eoipage7 from "../../../../../assert/Eoipage7.png";
import Eoipage8 from "../../../../../assert/Eoipage8.png";
export const pageMap = [
  {
    pageClassName: "page odd",
    title: "能源流向、月度电费账单、能耗管理月报",
    className: "title-second",
    line: true,
    leftContent: {
      img: {
        className: "introdece-img right",
        src: Eoipage3,
      },
    },
    rightContent: {
      introduce: {
        className: "introduce left top",
        title: {
          content: "能源流向",
          className: "noLine",
        },
        details: {
          className: "introduce-details",
          content: [
            "实时监测电网、光伏、储能等发电侧设备工作状态，能源流向清晰可见，精准掌握最新用电负荷变化信息。",
          ],
        },
      },
    },
  },
  {
    pageClassName: "page odd",
    line: true,
    leftContent: {
      introduce: {
        className: "introduce right top",
        title: {
          content: "月度电费账单",
          className: "noLine",
        },
        details: {
          className: "introduce-details",
          content: [
            "每月账单自动生成，电网用电，光伏用电，统一平台查看，方便快捷，电费账单不再后知后觉。",
            "按日/月/年，尖/峰/平/谷不同时间维度进行统计，各时间段电费支持自定义设置。",
          ],
        },
      },
    },
    rightContent: {
      img: {
        className: "introdece-img left",
        src: Eoipage4,
      },
    },
  },
  {
    pageClassName: "page odd",
    line: true,
    leftContent: {
      img: {
        className: "introdece-img right",
        src: Eoipage5,
      },
    },
    rightContent: {
      introduce: {
        className: "introduce left top",
        title: {
          content: "能耗管理月报",
          className: "noLine",
        },
        details: {
          className: "introduce-details",
          content: [
            "每月自动生成用户报告，从用能分布、最大需量、功率因素等不同角度分析园区电费组成，有针对性的给出建议。为园区企业加强能源管理，挖掘节能潜力提供基础数据支持。",
          ],
        },
      },
    },
  },

  {
    pageClassName: "page",
    title: "基于支路的各项统计",
    className: "title-second",
    line: true,
    leftContent: {
      introduce: {
        className: "introduce right top",
        title: {
          content: "支路用能",
          className: "noLine",
        },
        details: {
          className: "introduce-details",
          content: [
            "提供当前支路的负荷、最大负荷、平均负荷、负荷率、当日用能等用户关心的数据，全方位监测，全维度分析，实现用户精准化管理。",
          ],
        },
      },
    },
    rightContent: {
      img: {
        className: "introdece-img left",
        src: Eoipage6,
      },
    },
  },
  {
    pageClassName: "page",
    line: true,
    leftContent: {
      img: {
        className: "introdece-img right",
        src: Eoipage7,
      },
    },
    rightContent: {
      introduce: {
        className: "introduce left top",
        title: {
          content: "分项用能",
          className: "noLine",
        },
        details: {
          className: "introduce-details",
          content: [
            "对电能性质按照明、空调、动力、特殊等进行划分，对比分析一目了然，支持自定义。",
          ],
        },
      },
    },
  },
  {
    pageClassName: "page",
    line: true,
    leftContent: {
      introduce: {
        className: "introduce right top",
        title: {
          content: "部门用能",
          className: "noLine",
        },
        details: {
          className: "introduce-details",
          content: [
            "提供按部门进行能耗划分，将节能目标分解到部门，落实节能主体部门职责。",
          ],
        },
      },
    },
    rightContent: {
      img: {
        className: "introdece-img left",
        src: Eoipage8,
      },
    },
  },
];
