import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerLXSYDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import ImgPage from '../../ImgPage';
import LXDXY1 from '../../../../../assert/LXDXY1.png'
import LXDXY2 from '../../../../../assert/LXDXY2.png'
import LXDXY3 from '../../../../../assert/LXDXY3.png'
import LXDXY4 from '../../../../../assert/LXDXY4.png'
import LXDXY5 from '../../../../../assert/LXDXY5.png'
import LXDXY6 from '../../../../../assert/LXDXY6.png'
import LXDXY7 from '../../../../../assert/LXDXY7.png'
import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const LXDXYimgMap =[
  LXDXY1,LXDXY2,LXDXY3,LXDXY4,LXDXY5,LXDXY6,LXDXY7
]
export default class LXDXYset extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
      <ImgPage imgArr={LXDXYimgMap} history={this.props.history}/>
    )
  }
}
