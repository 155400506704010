import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerLXSYDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import DDS5168B1 from '../../../../../assert/DDS5168B1.png'
import DDS5168B2 from '../../../../../assert/DDS5168B2.png'
import DDS5168B3 from '../../../../../assert/DDS5168B3.png'
import DDS5168B4 from '../../../../../assert/DDS5168B4.png'
import DDS5168B5 from '../../../../../assert/DDS5168B5.png'
import DDS5168B6 from '../../../../../assert/DDS5168B6.png'
import DDS5168B7 from '../../../../../assert/DDS5168B7.png'
import ImgPage from '../../ImgPage';
import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const DDS5168BimgMap =[
  DDS5168B1,DDS5168B2,DDS5168B3,DDS5168B4,DDS5168B5,DDS5168B6,DDS5168B7
]
export default class DDS5168B extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
      <ImgPage imgArr={DDS5168BimgMap} history={this.props.history}/>
    )
  }
}
