import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerLXSYDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import ImgPage from '../../ImgPage';
import Joys6631 from '../../../../../assert/Joys6631.png'
import Joys6632 from '../../../../../assert/Joys6632.png'
import Joys6633 from '../../../../../assert/Joys6633.png'
import Joys6634 from '../../../../../assert/Joys6634.png'
import Joys6635 from '../../../../../assert/Joys6635.png'
import Joys6636 from '../../../../../assert/Joys6636.png'
import Joys6637 from '../../../../../assert/Joys6637.png'
import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const Joys663imgMap =[
  Joys6631,Joys6632,Joys6633,Joys6634,Joys6635,Joys6636,Joys6637
]
export default class JOYS663 extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
      <ImgPage imgArr={Joys663imgMap} history={this.props.history}/>
    )
  }
}
