import React, { Component } from "react";
import "./index.less";
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

export default class Tool extends Component {
     constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const { location,routes,history } = this.props
    return (
        <>
      <div className="content">
        <iframe title="tool" src="https://www.bilibili.com"/>
      </div>
      </>
    );
  }
}
