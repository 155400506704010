import Flatscard1 from "../../../../assert/Flatscard1.png";
import Flatscard2 from "../../../../assert/Flatscard2.png";
import Flatscard3 from "../../../../assert/Flatscard3.png";
import Flatscard4 from "../../../../assert/Flatscard4.png";
import Flatscard5 from "../../../../assert/Flatscard5.png";
import Flatscard6 from "../../../../assert/Flatscard6.png";
import Flatscard7 from "../../../../assert/Flatscard7.png";
import Flatscard8 from "../../../../assert/Flatscard8.png";
export const page2CardMap = [
    {
      img: Flatscard1,
      title:'用人成本高',
      list:['人力抄表成本高','人工带看成本高']
    },
    {
      img: Flatscard2,
      title:'工作效率低',
      list:['入住退房，事务繁琐','人工对账，效率低下']
    },
    {
      img: Flatscard3,
      title:'日常管理难',
      list:['房租水电催缴难','公区用电易纠纷']
    },
    {
      img: Flatscard4,
      title:'安全系数低',
      list:['水电异常难发现','人员出入无记录']
    },
  ];
export  const page2CardMap2 =[
      {
        img: Flatscard5,
        title:'两表一锁，降低管理成本',
        list:['在线实时抄表，减少人员支出，提高管理效率;','看房使用临时密码，节约带看房人工成本;']
      },
      {
        img: Flatscard6,
        title:'业务流程全覆盖，效率提升',
        list:['提供入住、退房、退款、充值等常用PMS功能，运营流程全覆盖;','多维度的数据报表让高效能源管理变得更简单;']
      },
      {
        img: Flatscard7,
        title:'在线催缴，公区分摊，管理人性化',
        list:['水电费与租金皆可催缴 ，解决收费难问题;','公区用量可分摊，分摊方式可选择，避免纠纷;']
      },
      {
        img: Flatscard8,
        title:'风险预警，居住安心',
        list:['设备异常可报警，智能化设备告警配置，用水用电异常及时提醒;','房间进出有记录，开锁记录实时查看，及时掌握人员出入情况;']
      },
    ];
export  const page2UlMap = [
      {
        content: "有线传输，稳定可靠；",
      },
      {
        content: "水电共用一个网关，节约网关成本；",
      },
      {
        content: "电表集中安装，节约施工布线材料。",
      }
    ];
export  const page2Ul2Map = [
      {
        content: "无需布线，水电/门锁可统一网关；",
      },
      {
        content: "私有网络，安全性强；",
      },
      {
        content: "灵活组网，数据传输稳定。",
      }
    ];