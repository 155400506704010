import React, { Component } from "react";
import "./index.less";
import { Col, Row } from "antd";
import {Helmet} from 'react-helmet';
import Aboutpage1 from '../../assert/Aboutpage1.png'
import Aboutpage2 from '../../assert/Aboutpage2.png'
import AboutIcon from  '../../assert/AboutIcon.png';
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../Home/Banner2';
import {
    BannerAboutDataSource,
    } from '../../Home/data.source';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

export default class About extends Component {
     constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const { location,routes,history } = this.props
    return (
        <>
         <Helmet>
         <title> 专注于通过技术变革实现能源管理全新价值 | 公司介绍 </title>
<meta name="description" content="源一物联是一家能源领域的物联网公司，专注于通过技术变革实现能源管理全新价值。"/>
<meta name="keywords" content="能源管理,能效,物联网,源一联系方式,eoiot" />
      </Helmet>
        <div>
        <Banner2
        id="Banner2_0"
        key="Banner2_0"
        dataSource={BannerAboutDataSource}
        isMobile={this.state.isMobile}
        location={location}
       /> 
        <div>
      <div>
        <div className="products_content">
          <div className="page">
            <span className="title">
              <h1>公司简介</h1>
            </span>
            <div>
              <Row>
                <Col xs={24} sm={10} md={10}>
                  <div className="introduce right out top">
                    <h3>我们做什么</h3>
                    <div className="line" />
                    <div className="introduce-details ">
                    杭州源一物联科技有限公司是一家能源领域的物联网公司，专注于通过技术变革实现能源管理全新价值。
                    <br/>
                    源一物联为公寓/公租房/工业园区/商业综合体/办公写字楼等业务场景提供一站式能源数字化解决方案，覆盖租赁与能效两大业务场景，累计服务客户近千家，产品与服务得到业内认可。
                    </div>
                    <div className="float-icon">
                        <img src={AboutIcon} alt=''/>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={14} md={14}>
                  <div className="introdece-img left">
                    <img src={Aboutpage1} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="page">
          <span className="title">
              <h1>联系我们</h1>
            </span>
            <div>
              <Row>
                <Col xs={24} sm={24} md={24}>
                  <div className="introdece-img">
                    <img src={Aboutpage2} alt="" />
                    <div className="map">
                        <div className="map-title"> 
                        杭州源一物联科技有限公司 </div>
                        <div className="map-content">
                        联系地址:  杭州市滨江区长河街道中赢星海创投中心2幢1804室
                        <br />
                        服务热线:  4008-266-829
                        <br />
                        传真：0573-82237330
                        <br />
                        邮箱：dmarketing@joymeter.com
                        </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
         
          </div>
        
        </div>
      </div>
      </div>
      </div>
      </>
    );
  }
}
