import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerLXSYDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import ImgPage from '../../ImgPage';
import LXSY1 from '../../../../../assert/LXSY1.png'
import LXSY2 from '../../../../../assert/LXSY2.png'
import LXSY3 from '../../../../../assert/LXSY3.png'
import LXSY4 from '../../../../../assert/LXSY4.png'
import LXSY5 from '../../../../../assert/LXSY5.png'
import LXSY6 from '../../../../../assert/LXSY6.png'
import LXSY7 from '../../../../../assert/LXSY7.png'
import { Button } from 'antd';


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const LXSYimgMap =[
  LXSY1,LXSY2,LXSY3,LXSY4,LXSY5,LXSY6,LXSY7
]
export default class LXSYset extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
     <ImgPage imgArr={LXSYimgMap} history={this.props.history}/>
    )
  }
}
