import React, { Component } from 'react'
import { enquireScreen } from 'enquire-js';
import Banner2 from '../../../../../Home/Banner2';
import {BannerDataSource} from '../../../../../Home/data.source'
import '../../../index.less'
import ImgPage from '../../ImgPage';
import DDSU2P1 from '../../../../../assert/DDSU2P1.png'
import DDSU2P2 from '../../../../../assert/DDSU2P2.png'
import DDSU2P3 from '../../../../../assert/DDSU2P3.png'
import DDSU2P4 from '../../../../../assert/DDSU2P4.png'
import DDSU2P5 from '../../../../../assert/DDSU2P5.png'
import DDSU2P6 from '../../../../../assert/DDSU2P6.png'
import DDSU2P7 from '../../../../../assert/DDSU2P7.png'
import DDSU2P8 from '../../../../../assert/DDSU2P8.png'

import { Button } from 'antd';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const DDSU2PimgMap =[
  DDSU2P1,DDSU2P2,DDSU2P3,DDSU2P4,DDSU2P5,DDSU2P6,DDSU2P7,DDSU2P8
]
export default class DDSU2P extends Component {
  constructor(props){
    super(props);
    this.state = {
        isMobile,
    }
}
componentDidMount(){
  document.documentElement.scrollTop = 0
}
  render() {
    const {location} = this.props
    return (
      
     <ImgPage imgArr={DDSU2PimgMap} history={this.props.history}/>
    )
  }
}
