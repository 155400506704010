import React, { Component } from "react";
import "../../index.less";
import { Col, Row } from "antd";
import {page2CardMap,page2CardMap2} from './data'
import OfficeBuilding1 from "../../../../assert/OfficeBuilding1.png";


export default class OfficeBuilding extends Component {
  render() {
    return (
      <div>
        <div className="products_content">
          <div className="page">
            <span className="title other-title">
              <h1>写字楼解决方案</h1>
              <div>个性化场景设计，打造绿色建筑最佳解决方案</div>
            </span>
            <div>
              <Row>
                <Col xs={24} sm={24} md={24}>
                  <div className="introdece-img">
                    <img src={OfficeBuilding1} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="page odd">
            <span className="title-second">
              <h2>痛点分析</h2>
              <div className="line" />
            </span>
            <div className="row-width">
              <Row gutter={[32, 16]}>
                  {page2CardMap.map((item, key)=>{
                      return (
                        <Col xs={24} sm={12} md={12} lg={6} key={key}>
                        <div className="row-card">
                           <div className="card-img">
                               <img src={item.img} alt=''/>
                           </div>
                           <div className="card-content">
                                <div className="card-content-title">{item.title}</div>
                               <div className="card-content-detail">
                                   {item.list}
                               </div>
                           </div>
                        </div>
                    </Col>
                      )
                  })}
              </Row>
            </div>
          </div>
          <div className="page">
            <div>
              <div className="introduce">
                <h2>方案优势</h2>
                <div className="line" />
              </div>
              <div className="row-width">
              <Row gutter={[24, 16]}>
                  {page2CardMap2.map((item, key)=>{
                      return (
                        <Col  xs={24} sm={12} md={12} lg={6} key={key}>
                        <div className="row-card2">
                           <div className="card-img">
                               <img src={item.img} alt=''/>
                           </div>
                           <div className="card-content">
                                <div className="card-content-title">{item.title}</div>
                                <div className="card-content-detail">
                                   {item.list}
                               </div>
                           </div>
                        </div>
                    </Col>
                      )
                  })}
              </Row>
            </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}
