import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col,Button } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import rightBlueIcon from '../assert/rightBlueIcon.png'

function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile,history,type } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
 const linkTo = ()=>{
  history.push({pathname:'/software/products',state:{type}})
 }
 
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <span {...dataSource.img}>
            <img src={dataSource.img.children} width="100%" alt="img" />
          </span>
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <h2 key="h1" {...dataSource.title}>
            {dataSource.title.children}
          </h2>
          <div key="p" {...dataSource.content}>
            {dataSource.content.children}
          </div>
          <div key='btn' {...dataSource.button} >
          <Button
          onClick={linkTo}
           type="default" shape="round" size='large' style={{padding:'0 50px 0 60px',fontSize:'13px',}}>
            查看详情
          <span style={{width:'60px',marginLeft:'24px'}}>
          <img src={rightBlueIcon} alt='' width={'100%'}/>
          </span>
        </Button>
          </div>
        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content1;
