/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import {Helmet} from 'react-helmet';
import { enquireScreen } from 'enquire-js';
import Rolltop from '../components/Rolltop';
import Nav0 from './Nav0';
import Banner2 from './Banner2';
import Pricing1 from './Pricing1';
import Footer1 from './Footer1';
import Teams1 from './Teams1';
import { Switch,Route,  } from 'react-router-dom';
import { routes } from '../model/routes';


import {
  Nav00DataSource,
  Banner20DataSource,
  Pricing10DataSource,
  Teams10DataSource,
  Footer10DataSource,
  Pricing11DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};
 class HomeOter extends React.Component{
  constructor(props) {
    super(props);
    
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }}
    render() {
      const {location} = this.props
      return (
        <>
         <Banner2
          id="Banner2_0"
          key="Banner2_0"
          dataSource={Banner20DataSource}
          isMobile={this.state.isMobile}
          location={location}
        />
        <Pricing1
        id="Pricing1_1"
        key="Pricing1_1"
        dataSource={Pricing11DataSource}
        isMobile={this.state.isMobile}
      />
        <Pricing1
          id="Pricing1_0"
          key="Pricing1_0"
          dataSource={Pricing10DataSource}
          isMobile={this.state.isMobile}
        />
        <Teams1
          id="Teams1_0"
          key="Teams1_0"
          dataSource={Teams10DataSource}
          isMobile={this.state.isMobile}
        />
        <Footer1
          id="Footer1_0"
          key="Footer1_0"
          dataSource={Footer10DataSource}
          isMobile={this.state.isMobile}
        />
        </>
      )
    }
  
 
  }



 class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      showTop:false,
    };
  }

  componentDidMount() {

    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
 
  }
  componentDidUpdate(_,preState){
    window.addEventListener('scroll',()=>{
      const distance = document.documentElement.scrollTop
      if (distance >=1200 &&preState.showTop === false ){
        this.setState({showTop:true})
      }else if (distance<1200 && preState.showTop === true) {
        this.setState({showTop:false})
      }
      // console.log(distance)
    })
   
  }

  render() {
    return (
    <>
    <Helmet>
    <title>源一物联 | 能源管理数字化解决方案服务商</title>
     <meta name="description" content="源一物联为能源计量赋予IOT连接和数据服务能力, 创造能源管理新价值。" />
     <meta name="keywords" content="源一物联,能源管理,物联网,智能水表,智能电表"/>
    </Helmet>
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
        style={{position:'relative',minWidth:'300px'}}
      >
        
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show &&  
        <Nav0
        id="Nav0_0"
        key="Nav0_0"
        dataSource={Nav00DataSource}
        isMobile={this.state.isMobile}
      />}
        {!this.state.isMobile&&this.state.showTop &&<Rolltop />}
        <Switch>
          {
            routes.map((route,index)=>{
              if(route.exact){
                return  <Route key={index} path={route.path}  render={props => (
                  <route.component {...props} routes={route.routes} />
                )} exact/>
              }else {
                return  <Route key={index} path={route.path}  render={props => (
                  <route.component {...props} routes={route.routes} />
                )}/>
              }
            })
          }
          {/* <Route path='/hardware/LXSY' component={LXSYset} /> */}
       <Route path='/' component={HomeOter} />
        </Switch>
        {/* 如果不是 dva 2.0 替换成 {children} end */}
        
      </div>
      </>
    );
  }
}
export default Home
