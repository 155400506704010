import React from "react";
import { Button, Breadcrumb } from "antd";
import { DownOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";
import TweenOne, { TweenOneGroup } from "rc-tween-one";
import BannerAnim, { Element } from "rc-banner-anim";
import { isImg, routeRender } from "./utils";
import "rc-banner-anim/assets/index.css";

//路由路径转为面包屑
const breadMap = {
  hardware: "硬件产品",
  software: "软件产品",
  LXSY: "LXSY系列",
  JOYS663: "JOYS663",
  LXDXY: "LXDXY系列",
  DDSU2P: "DDSU5168",
  DDS5168B: "DDS5168B",
  DDSU4P: "DDSU5168",
  DTSD5168: "DTSD5168",
  JOYR200: "JOYR200",
  JOYH100: "JOYH100",
  CT213: "JOYC-CT213",
  CV151: "JOYC-CV151",
  GW220: "JOYC-GW220",
  solution: "解决方案",
  about:'关于我们'
};

const BgElement = Element.BgElement;
class Banner extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = dataSource.BannerAnim.children.map((item, i) => {
      const elem = item.BannerElement;
      const elemClassName = elem.className;
      delete elem.className;
      const bg = item.bg;
      const textWrapper = item.textWrapper;
      const title = item.title;
      const content = item.content;
      const button = item.button;
      const page = item.page;
      const breadcrumb = item.breadcrumb;
      const follow = !isMobile
        ? {
            delay: 1000,
            minMove: 0.1,
            data: [
              {
                id: `bg${i}`,
                value: 15,
                type: "x",
              },
              { id: `wrapperBlock${i}`, value: -15, type: "x" },
            ],
          }
        : null;
      return (
        <Element
          key={i.toString()}
          followParallax={follow}
          {...elem}
          prefixCls={elemClassName}
        >
          <BgElement key="bg" {...bg} id={`bg${i}`} />
          <div {...page}>
            {this.props.location.pathname !== "/" && (
              <div key="breadcrumb" {...breadcrumb}>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>首页</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {routeRender(this.props.location.pathname)}
                  </Breadcrumb.Item>
                  {this.props.location.pathname.split("/").map((item) => {
                    return (
                      <Breadcrumb.Item key={item}>
                        {breadMap[item]}
                      </Breadcrumb.Item>
                    );
                  })}
                </Breadcrumb>
              </div>
            )}
            <QueueAnim
              type={["bottom", "top"]}
              delay={200}
              key="text"
              {...textWrapper}
              id={`wrapperBlock${i}`}
            >
              <div key="logo" {...title}>
                {typeof title.children === "string" &&
                title.children.match(isImg) ? (
                  <img src={title.children} width="100%" alt="img" />
                ) : (
                  title.children
                )}
              </div>
              <div key="content" {...content}>
                {content.children}
              </div>

              <Button type="ghost" key="button" {...button}>
                {button.children}
              </Button>
            </QueueAnim>
          </div>
        </Element>
      );
    });
    return (
      <div {...props} {...dataSource.wrapper}>
        <TweenOneGroup
          key="bannerGroup"
          enter={{ opacity: 0, type: "from" }}
          leave={{ opacity: 0 }}
          component=""
        >
          <BannerAnim key="BannerAnim" {...dataSource.BannerAnim}>
            {childrenToRender}
          </BannerAnim>
        </TweenOneGroup>
        <TweenOne
          animation={{
            y: "-=20",
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner2-icon"
          style={{ bottom: 40 }}
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      </div>
    );
  }
}

export default Banner;
