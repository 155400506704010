import Flatscard1 from "../../../../assert/Flatscard1.png";
import Flatscard2 from "../../../../assert/Flatscard2.png";
import Flatscard3 from "../../../../assert/Flatscard3.png";
import Flatscard4 from "../../../../assert/Flatscard4.png";
import Flatscard5 from "../../../../assert/Flatscard5.png";
import Flatscard6 from "../../../../assert/Flatscard6.png";
import Flatscard7 from "../../../../assert/Flatscard7.png";
import Flatscard8 from "../../../../assert/Flatscard8.png";

export const page2CardMap = [
    {
      img: Flatscard1,
      title:'房源众多管理难',
      list:['转租转借，监管困难','飞房现象，难以识别']
    },
    {
      img: Flatscard2,
      title:'事物繁琐效率低',
      list:['入住退房，事务繁琐','人工对账，效率低下']
    },
    {
      img: Flatscard3,
      title:'日常琐碎纠纷多',
      list:['房租水电，催缴困难','拒不腾退，劝导费劲']
    },
    {
      img: Flatscard4,
      title:'安全隐患排查难',
      list:['水电异常，难以发现','人员出入，管控困难']
    },
  ];
export  const page2CardMap2 =[
      {
        img: Flatscard5,
        title:'两表一锁，双重管控',
        list:['智能门锁指纹认证，杜绝转租转借现象;','水电用量异常分析，有效防止飞房现象;']
      },
      {
        img: Flatscard6,
        title:'业务流程全覆盖，效率提升',
        list:['提供入住、退房、退款、充值等常用PMS功能，运营流程全覆盖;','多维度的数据报表让高效能源管理变得更简单;']
      },
      {
        img: Flatscard7,
        title:'在线催缴，以电管人',
        list:['水电费与租金皆可催缴 ，解决收费难问题;','面对拒不腾退现象，可实行房间断电操作;']
      },
      {
        img: Flatscard8,
        title:'风险预警，居住安心',
        list:['设备异常可报警，智能化设备告警配置，用水用电异常及时提醒;','房间进出有记录，开锁记录实时查看，及时掌握人员出入情况;']
      },
    ];
